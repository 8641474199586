import axios from 'axios';

axios.defaults.timeout = 20000;
axios.defaults.baseURL = '';


//http request 拦截器
axios.interceptors.request.use(config => {
    // const token = getCookie('名称');注意使用的时候需要引入cookie方法，推荐js-cookie
    // config.headers = {
    //     'Content-Type': 'application/x-www-form-urlencoded'
    //     // 'Content-Type': 'application/json;charset=UTF-8'
    // };
    // if(token){
    //   config.params = {'token':token}
    // }
    //axios.defaults.withCredentials=true;//添加这句以完成SESSION
    // config.headers['Device-Data'] = '';
    // config.headers['Accept'] = 'application/json, text/javascript, */*; q=0.01';

    return config;
}, error => {
    return Promise.reject(error);
});


//http response 拦截器
// axios.interceptors.response.use(
//     response => {
//         if (response.data.errCode == 2) {
//             router.push({
//                 path: "/login",
//                 querry: {redirect: router.currentRoute.fullPath}//从哪个页面跳转
//             })
//         }
//         return response;
//     },
//     error => {
//         return Promise.reject(error)
//     }
// );


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.defaults.timeout = 60000;
        axios.get(url, {
            params: params
        })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function getJson(url) {
    return new Promise((resolve, reject) => {
        axios.defaults.timeout = 15000;
        axios.get(url, config => {
            config.headers = {
                'Content-Type': 'application/json'
            };
            return config;
        }).then(response => {
            resolve(response.data);
        }, err => {
            reject(err)
        })
    })
}

/**
 * 推荐使用
 * 封装的一件调用的get方式,
 * @param url
 * @param success
 * @param error
 */
export function getJsonSimple(url, success, error) {
    get(url).then(function (response) {
        if (success) {
            success(response);
        }
    }, function (_error) {
        if (error) {
            error(_error)
        }
    });
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.defaults.timeout = 15000;
        axios.post(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 推荐使用
 * 封装的一件调用的post方式
 * @param url
 * @param data
 * @param success
 * @param error
 */
export function postJsonSimple(url, data, success, error) {
    postJson(url, data).then(function (response) {
        if (success) {
            success(response);
        }
    }, function (_error) {
        if (error) {
            error(_error)
        }
    });
}


export function postJson(url, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.timeout = 15000;
        // let _data= JSON.parse(data);
        axios.post(url, data, config => {
            config.headers = {
                'Content-Type': 'application/json'
            };
            return config;
        }).then(response => {
            resolve(response.data);
        }, err => {
            reject(err)
        })
    })
}


export function postData(url, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.timeout = 15000;
        let _data = transformData(data);
        axios.post(url, _data, config => {
            config.data = _data
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
            return config;
        }).then(response => {
            resolve(response.data);
        }, err => {
            reject(err)
        })
    })
}

export function postForm(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.defaults.timeout = 15000;
        // data: JSON.stringify(data),
        axios.post(url, data, config => {
            config.data = JSON.stringify(data);
            config.headers = {
                'Content-Type': 'application/x-www-form-urlencoded'
            };
            return config;
        })
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}


export function postImg(url, data) {
    return new Promise((resolve, reject) => {
        axios.defaults.timeout = 30000;
        // withCredentials: true 使得后台可以接收表单数据  跨域请求
        // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

        axios.post(url, data, config => {
            config.headers = {
                'Content-Type': 'multipart/form-data', 'enctype': 'multipart/form-data'
                // 'Content-Type':'application/x-www-form-urlencoded'
            };
            return config;
        })
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })

        // let config = {
        //     headers: {
        //         'Content-Type': 'multipart/form-data'
        //     }
        // }
        //
        // axios.post(url,data, config).then(res => {
        //     // success callback
        //     resolve(res.data);
        // }).catch(err => {
        //     // error callback
        //     reject(err)
        // });
    })
}


export function transformData(data) {
    let ret = '';
    for (let key in data) {
        ret += encodeURIComponent(key) + '=' + encodeURIComponent(data[key]) + '&';
    }
    if (ret.endsWith('&')) {
        ret = ret.substring(0, ret.length - 1)
    }
    return ret;
}
