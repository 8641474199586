<template>
  <div>
    <privacy_policy_mini app-name="快购够"></privacy_policy_mini>
  </div>
</template>
<script>
import privacy_policy_mini from "@/views/doc/privacy_policy_mini.vue";

export default {
  components: {privacy_policy_mini},
  created() {
    document.title='隐私政策'
  }
}
</script>