/*
 * @Author: your name
 * @Date: 2022-01-25 21:30:58
 * @LastEditTime: 2022-02-07 21:26:30
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \za-shop-manager\src\editor\config\index.js
 */
/**
 * 公共配置文件
 */
// import $config from '../../config'


const configDict = {
    development: {
        baseURL: 'http://localhost:8080'
    },
    production: {
        baseURL: ""
    }
}

const currentConfigKey = process.env.NODE_ENV
const isDev = process.env.NODE_ENV === 'development'
const isTest = process.env.NODE_ENV === 'development'

const configObj = {
    isDevelop: (isDev || isTest),
    ...configDict[currentConfigKey],
    // h5模式宽高
    canvasH5Width: 375,
    canvasH5Height: 644,
    pageModeList: [{
        value: 'h5',
        label: 'H5',
        disabled: false
    }, {
        value: 'longPage',
        label: '长页H5',
        disabled: false
    }, {
        name: 'relativePage',
        label: '排版图文',
        disabled: true
    }, {
        value: 'pc',
        label: 'PC页面',
        disabled: true
    }]
}

export default configObj
