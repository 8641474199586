<template>
  <div id="down2" :style="{height:this.$getViewPortSize().height+'px'}">
    <div class="icon-area2" :style="{height:topAreaHeight+'px'}">
      <div class="icon-div">
        <van-image :src="icon" :radius="20" width="70px"/>
      </div>
    </div>

    <div class="down-area2">
      <div class="app-title black">
        挚爱母婴
      </div>
      <div class="content-style">
        <div>
          母婴用品B2B分销平台
        </div>
        <div>
          正品保证 一件代发
        </div>
      </div>
      <div class="down-area1">
        <div v-if="isAndroid()===true">
          <van-button type="danger" block plain class="down-text" @click="download">Android版本下载</van-button>
        </div>
        <div v-if="isOS()===true">
          <van-button type="danger" block plain class="down-text" @click="download">iPhone版本下载</van-button>
        </div>
      </div>
      <div class="version-info" v-if="isAndroid()===true && (versionData['ios'] || versionData['android'])">
        <span>版本:{{ versionData['android']['versionCode'] }} 更新时间:{{ versionData['android']['versionDate'] }}</span>

      </div>
      <div class="version-info" v-if="isOS()===true">
        <span v-if="isOS()===true && versionData['ios']">版本:{{ versionData['ios']['versionCode'] }} 更新时间:{{ versionData['ios']['versionDate'] }}</span>
      </div>
      <FootMore color="#0f3645"/>

    </div>

    <van-popup v-model="show" @click="show=false">
      <DownTip></DownTip>
    </van-popup>
  </div>
</template>

<script>

import DownTip from "@/components/DownTip";
import FootMore from "@/components/FootMore";

export default {
  name: 'Home',
  components: {FootMore, DownTip},
  data() {
    return {
      show: false,
      height: 800,
      topAreaHeight: 400,
      downAreaHeight: 200,
      icon: '/image/icon_460.webp',
      iconBack: "/image/text_back.png",
      androidUrl: 'http://cdnimage.babyzhiai.net/APP/com.babyzhiai.m_release_4.6.6.apk',
      // androidUrl: 'https://www.babyzhiai.net/user/com.babyzhiai.m_20210824210015.apk',
      iOSUrl: 'https://apps.apple.com/cn/app/id1234146518',
      versionData: {},
    }
  },
  beforeMount() {
    this.height = this.$getViewPortSize().height
    this.downAreaHeight = this.height - this.topAreaHeight
  },
  created() {
    this.getAppVersion()
  },
  methods: {
    getAppVersion() {
      this.$apiUtils.getAppVersion((res) => {
        console.log(res)
        this.versionData = res;
      })
    },
    isAndroid() {
      let ua = navigator.userAgent.toLowerCase();
      //android终端
      let isAndroid = ua.indexOf('android') > -1 || ua.indexOf('adr') > -1;

      return isAndroid
    },
    isOS() {
      let ua = navigator.userAgent.toLowerCase();
      let isiOS = !!ua.match(/\(i[^;]+;( u;)? cpu.+mac os x/);
      return isiOS
    },
    download() {
      // let ua = navigator.userAgent.toLowerCase();
      //android终端
      // let isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1; //ios终端
      // let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

      if (this.isWeixinBrowser()) {
        this.show = !this.show;
      } else {
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          //ios
          window.location = this.versionData['ios']['versionUrl']

        } else if (/(Android)/i.test(navigator.userAgent)) {
          //android
          window.location = this.versionData['android']['versionUrl']
        }
      }
    },
    isWeixinBrowser() {
      let ua = navigator.userAgent.toLowerCase();
      return (/micromessenger/.test(ua)) ? true : false;
    }
  }
}
</script>
<style>
#down2 {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #d8fafe;
}

.icon-div {
  /*margin: 50px;*/
  width: 100%;
  position: absolute;
  top: 130px;
  text-align: center;
  z-index: 99;
}

.backIcon {
  width: 100%;
  position: absolute;
  top: 0px;
}

.icon-area2 {
  background-color: #d8fafe;
  background: url("/image/text_back.png") no-repeat center 0px;
  background-size: cover;
}

.down-area2 {
  background-color: #d8fafe;
  /*background: url("//img12.360buyimg.com/img/s750x560_jfs/t1/153545/8/6484/2583/5fb640d0E23919ec4/deffc68d519a1076.png") no-repeat;*/
}

.down-area1 {
  padding: 10px;
}

.app-title {
  font-size: 30px;
  font-weight: bold;
  padding: 10px;
  color: #0f3645;
}

.content-style {
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  color: #0f3645;
}

.down-area {
  padding: 20px;
}

.down-text {
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
}

.version-info {
  margin-top: 20px;
  font-size: 12px;
}

</style>

