<template>
  <div class="wx-down"
       :style="{width:this.$getViewPortSize().width+'px',height:this.$getViewPortSize().height+'px'}">
    <div class="tip-text">
      <div>点击右上角</div>
      <div>选择在默认浏览器中打开</div>
    </div>
    <div class="app-info">
      <van-image :src="icon" :radius="20" width="70px"/>
      <div class="app-title2">
        挚爱母婴
      </div>
    </div>
    <div class="opt">
      <van-image :src="arrow" width="70px"/>
    </div>
    <FootMore/>
  </div>
</template>
<script>
import FootMore from "@/components/FootMore";
export default {
  components: {FootMore},
  data(){
    return{
      arrow: '/image/arrow.png',
      icon: '/image/icon_460.webp',
    }
  }
}
</script>
<style>
.app-title2 {
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  color: #2c3e50;
}

.app-info{
  padding-top:50px;
}

.wx-down {
  /*position: absolute;*/
  height: 300px;
  background-color: #FFFFFF;
  /*background-color: #42b983;*/
}


.tip-text {
  padding-top: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #2c3e50;
}

.opt {
  display: block;
  content: "";
  position: absolute;
  right: 60px;
  top: .6rem;
  width: 1.85rem;
  height: 2.625rem;
}
</style>