<template>
  <div>
    <div>
      <div class="bigTitle">隐私政策</div>
      <div class="content">
        本政策仅适用于<span class="name">{{ copName }}</span>的<span class="name">挚爱母婴</span>app(以下简称本应用)产品或服务。
      </div>
      <PrivacyContent></PrivacyContent>
    </div>
  </div>
</template>
<script>
import PrivacyContent from "@/views/doc/PrivacyContent";

export default {
  components: {PrivacyContent},
  data() {
    return {
      userAgent: '',
      copName: '上海挚爱母婴用品有限公司',
    }
  },
  created() {
  }
}
</script>
<style>
body {
  padding: 10px;
}

.bigTitle {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}

.content {
  margin-left: -0.2pt;
  font-size: 14px;
}

.name {
  font-weight: bold;
}
</style>