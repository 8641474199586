/*
 * @Author: xguang 19933226@qq.com
 * @Date: 2024-08-11 15:08:40
 * @LastEditors: xguang 19933226@qq.com
 * @LastEditTime: 2024-09-02 09:02:28
 * @FilePath: /za-h5/src/js/commons.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const corpCommons = {};
export default corpCommons;


corpCommons.corpAppId = 'ww2f3a48301814994b';
corpCommons.corpAgentId = '1000002';
// let agentAppId = corpAppId + '_1000002';

corpCommons.api_host = "https://api.kumob.cn/za"
corpCommons.api_host_test = "https://api.kumob.cn/za_test"
corpCommons.wechat_api_host = "https://api.kumob.cn/wx"


corpCommons.api_get_corp_js_api_ticket = corpCommons.wechat_api_host + `/api/corp/get_jsapi_ticket/${corpCommons.corpAppId}`
corpCommons.api_get_corp_agent_js_api_ticket = corpCommons.wechat_api_host + `/api/corp/get_agent_jsapi_ticket/${corpCommons.corpAppId}_${corpCommons.corpAgentId}`

corpCommons.api_get_corp_js_api_ticket_sign = corpCommons.wechat_api_host + `/api/corp/get_jsapi_ticket/sign/${corpCommons.corpAppId}`
corpCommons.api_get_corp_agent_js_api_ticket_sign = corpCommons.wechat_api_host + `/api/corp/get_agent_jsapi_ticket/sign/${corpCommons.corpAppId}_${corpCommons.corpAgentId}`

corpCommons.api_get_corp_chat_group_detail=corpCommons.wechat_api_host + `/api/corp/chat_group/get_detail/${corpCommons.corpAppId}`


corpCommons.api_corp_user_search = corpCommons.api_host + '/api/user/tags/search'  //{filterArray:['corpExtUserId=1233']}

corpCommons.api_spu_search=corpCommons.api_host+'/api/stock_spu/search'   //{key,page,size}


corpCommons.api_spu_url_link=corpCommons.wechat_api_host+'/api/mini_app/get_url_link'  //{app_id,}