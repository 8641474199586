<template>
    <div>
        <div>
            $config.canvasH5Width:{{ $config.canvasH5Width }}
        </div>
        <div>
            width:{{ width }}
        </div>
        <div>
            this.scalingRatio:{{ this.scalingRatio }}
        </div>
        <div>
            pageTop:{{ pageTop }}
        </div>
        <div>
            <button @click="callFlutter">js Text</button>
        </div>
        <div>
            <button @click="callMap">js Map</button>
        </div>
        <div>
            <button @click="callJSON">js Json</button>
        </div>

        <div>
            <button @click="callSkuSelect">js skuSelect 方案1</button>
        </div>

        <div>
            <button @click="callSkuSelect2">js skuSelect 方案2</button>
        </div>

        <div>
            <button @click="checkInApp">js isInApp</button>
        </div>

        <div>
            <button @click="callDeviceInfo">js callDeviceInfo</button>
        </div>

        <div>
            <button @click="callUserData">js callUserData</button>
        </div>

        <p id="p1" style="visibility:hidden;">
            Flutter 调用了 JS.
            Flutter 调用了 JS.
            Flutter 调用了 JS.
        </p>

        <div>
            isInApp:
            {{ isInApp }}
        </div>

        <div>
            userData:
            {{ userData }}
        </div>
        <div>
            deviceInfo:
            {{ deviceInfo }}
        </div>
    </div>
</template>
<script>
import $config from "@/config";
import appCall from "@/js/appCall";

export default {
    data() {
        return {
            scalingRatio: 1,
            pageTop: 0,
            width: 0,
            jsonStr: '',
            jsonStr2: '',
            isInApp: false,
            userData: {},
            deviceInfo: {},
        }
    },
    created() {
        this.width = document.documentElement.clientWidth
        // this.scalingRatio = document.body.clientWidth / $config.canvasH5Width
        this.scalingRatio = this.width / $config.canvasH5Width
        console.log(this.scalingRatio)
        this.scalingRatio = 1.5
        // this.pageTop = (document.documentElement.clientHeight - this.pageData.height * this.scalingRatio) / 2
        // this.pageTop = Math.max(this.pageTop, 0);
        // eslint-disable-next-line no-undef
    },
    mounted() {
        window.callJS = this.callJS
        // window['getUserDataBack'] = this.$appCall.getUserDataBack;
    },
    methods: {
        callFlutter() {
            this.$appCall.alert("js提示", "js消息内容")
        },
        callMap() {
            let data = {
                id: 9527,
                title: "测试标题",
                message: '测试内容'
            }
            ///可直接传js对象
            this.$appCall.postData(data)
        },
        callJSON() {
            let data = {
                id: 9527,
                title: "测试标题",
                message: '测试内容'
            }
            ///必需转一下json，app才可识别
            this.$appCall.postData(JSON.stringify(data))
        },
        callSkuSelect() {
            ///必需转一下json，app才可识别
            this.$appCall.sukSelect('18108')
        },
        callSkuSelect2() {
            ///必需转一下json，app才可识别
            this.$appCall.sukSelectByConfig({productId: '18108'})
        },
        callJS(message) {
            document.getElementById("p1").style.visibility = message;
        },
        checkInApp() {
            this.$appCall.isInApp({
                success: (data) => {
                    this.isInApp = data;
                }
            })
        },
        callUserData() {
            this.$appCall.getUserData({
                success: (data) => {
                    this.userData = data;
                }
            })
        },
        callDeviceInfo() {
            this.$appCall.getDeviceInfo({
                success: (data) => {
                    this.deviceInfo = data;
                }
            })
        }
    }
}
</script>