<template>
  <div>
    <div>
      品牌页面
    </div>
    <div>
      当前id:{{ id }}
    </div>
    <div>
      当前bid:{{ bid }}
    </div>
  </div>

</template>
<script>
export default {
  data() {
    return {
      id: '',
      bid:'',
    }
  },
  created() {
    this.id = this.$utils.getUrlKey("bid");
    this.bid = this.$utils.getUrlKey("bid");
  },
  methods: {
    init() {

    }
  }
}
</script>