<template>
  <div id="down2" :style="{ height: this.$getViewPortSize().height + 'px' }">
    <div class="icon-area" :style="{ height: topAreaHeight + 'px' }">
      <div class="backIcon" style="margin-top: 20px">
        <van-image :src="icon" :radius="20" width="120px"/>
      </div>
    </div>
    <div class="down-area2">
      <div class="app-title black">挚爱母婴</div>
      <div class="content-style">
        <div>内部测试下载</div>
        <div></div>
      </div>
      <div class="down-area2">
        <div v-if="isAndroid() === true">
          <van-button
              type="danger"
              block
              plain
              class="down-text"
              @click="download()"
          >Android版本4.6.6 下载(测试)
          </van-button>
        </div>
        <div v-if="isOS() === true">
          暂无测试版本下载
          <!--                    <van-button-->
          <!--                            type="danger"-->
          <!--                            block-->
          <!--                            plain-->
          <!--                            class="down-text"-->
          <!--                            @click="download(3)"-->
          <!--                    >iPhone版本下载-->
          <!--                    </van-button>-->
        </div>
      </div>
      <zaFootMore color="#0f3645"/>
    </div>

    <van-popup v-model="show" @click="show = false">
      <DownTip></DownTip>
    </van-popup>
  </div>
</template>

<script>
import DownTip from "@/components/DownTip";
import zaFootMore from "@/components/zaFootMore";

export default {
  name: "Home",
  components: {zaFootMore, DownTip},
  data() {
    return {
      zalogo: require("../../assets/icon_400.png"),
      show: false,
      height: 800,
      topAreaHeight: 150,
      downAreaHeight: 200,
      // icon: "/image/icon_400.webp",
      icon: '/image/icon_460.webp',
      iconBack:
          "//img12.360buyimg.com/img/s565x552_jfs/t1/129833/10/16542/13563/5fb640c7E24bcd092/a79dee8615655328.png",
      androidUrl: 'https://h5.babyzhiai.net/down/com.babyzhiai.m_release_4.6.6.apk',
      // androidUrl:
      //     "https://api.kumob.cn/za/down/com.babyzhiai.m_release.apk",
      // androidUrl:
      //     "https://www.babyzhiai.net/user/com.babyzhiai.m_20210824210015.apk",
      androidbetaUrl: "https://api.kumob.cn/za/down/za-app-beta.apk",
      iOSUrl:
          "https://apps.apple.com/cn/app/%E6%8C%9A%E7%88%B1%E6%AF%8D%E5%A9%B4-%E8%BF%9B%E5%8F%A3%E5%93%81%E7%89%8C%E4%B8%80%E4%BB%B6%E4%BB%A3%E5%8F%91%E5%95%86%E5%9F%8E/id1234146518",
    };
  },
  beforeMount() {
    this.height = this.$getViewPortSize().height;
    this.downAreaHeight = this.height - this.topAreaHeight;
  },
  methods: {
    isAndroid() {
      let ua = navigator.userAgent.toLowerCase();
      //android终端
      let isAndroid = ua.indexOf("android") > -1 || ua.indexOf("adr") > -1;

      return isAndroid;
    },
    isOS() {
      let ua = navigator.userAgent.toLowerCase();
      let isiOS = !!ua.match(/\(i[^;]+;( u;)? cpu.+mac os x/);
      return isiOS;
    },
    download(id) {
      // let ua = navigator.userAgent.toLowerCase();
      //android终端
      // let isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1; //ios终端
      // let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

      if (this.isWeixinBrowser()) {
        this.show = !this.show;
      } else {
        window.location = this.androidUrl
      }
    },
    isWeixinBrowser() {
      let ua = navigator.userAgent.toLowerCase();
      return /micromessenger/.test(ua) ? true : false;
    },
  },
};
</script>
<style>
#down {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #d8fafe;
}

.icon-div {
  /*margin: 50px;*/
  width: 100%;
  position: absolute;
  top: 130px;
  text-align: center;
  z-index: 99;
}

.backIcon {
  width: 100%;
  position: absolute;
  top: 0px;
}

.icon-area {
  background-color: #fff;
}

.down-area {
  background-color: #d8fafe;
  background: url("/image/text_back.png") no-repeat center 0px;
  background-size: cover;
  /*background: url("//img12.360buyimg.com/img/s750x560_jfs/t1/153545/8/6484/2583/5fb640d0E23919ec4/deffc68d519a1076.png") no-repeat;*/
}

.down-area1 {
}

.app-title {
  font-size: 30px;
  font-weight: bold;
  padding: 10px;
  color: #0f3645;
}

.content-style {
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  color: #0f3645;
}

.down-area {
  padding: 20px;
}

.down-text {
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
}
</style>
