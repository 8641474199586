<template>
    <div>
        <div>
            {{ getUserAgent() }}
        </div>
        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('zhiai://')">zhiai://</van-button>
        </div>
        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('zhiai://home')">zhiai://home
            </van-button>
        </div>
        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('zhiai://spu?id=4231')">
                zhiai://spu?id=4231
            </van-button>
        </div>
        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('zhiai://brand_home?bid=1323')">
                zhiai://brand_home?bid=1323
            </van-button>
        </div>
        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('zhiai://article?id=640')">
                zhiai://article?id=640
            </van-button>
        </div>
        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('zhiai://https://bing.com')">
                zhiai://https://bing.com
            </van-button>
        </div>


        <div>
            <hr />
        </div>

        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('babyzhiai://')">babyzhiai://</van-button>
        </div>
        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('babyzhiai://home')">
                babyzhiai://home
            </van-button>
        </div>
        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('babyzhiai://spu?id=1146')">
                babyzhiai://spu?id=1146
            </van-button>
        </div>
        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('babyzhiai://brand_home?bid=1323')">
                babyzhiai://brand_home?bid=1323
            </van-button>
        </div>
        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('babyzhiai://article?id=640')">
                babyzhiai://article?id=640
            </van-button>
        </div>
        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('babyzhiai://https://bing.com')">
                babyzhiai://https://bing.com (App外使用)
            </van-button>
        </div>


        <div>
            <hr />
        </div>

        <div>
            Universal Link 方式用跨域方式打开，才会直接呼出.否则会打开当前H5页面,上方提示App打开.
        </div>
        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('https://mob.babyzhiai.net/')">
                https://mob.babyzhiai.net/
            </van-button>
        </div>
        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('https://mob.babyzhiai.net/home')">
                https://mob.babyzhiai.net/home
            </van-button>
        </div>
        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('https://mob.babyzhiai.net/spu?id=4231')">
                https://mob.babyzhiai.net/spu?id=4231
            </van-button>
        </div>
        <div>
            <van-button type="danger" class="down-text"
                @click="openUrlScheme('https://mob.babyzhiai.net/brand_home?bid=1323')">
                https://mob.babyzhiai.net/brand_home?bid=1323
            </van-button>
        </div>
        <div>
            <van-button type="danger" class="down-text"
                @click="openUrlScheme('https://mob.babyzhiai.net/article?id=640')">
                https://mob.babyzhiai.net/article?id=640
            </van-button>
        </div>

        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('https://mob.babyzhiai.net/test')">
                https://mob.babyzhiai.net/test
            </van-button>
        </div>

        <div>
            <hr />
        </div>

        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('/spu?id=4231')">
                https://mob.babyzhiai.net/spu?id=4231
            </van-button>
        </div>

        <div>
            <van-button type="danger" class="down-text" @click="openUrlScheme('/brand_home?bid=1323')">
                /brand_home?bid=1323
            </van-button>
        </div>

        <div>
            <van-button type="danger" class="down-text" @click="setCookie()">
                写Cookie
            </van-button>
        </div>
        <div>
            <van-button type="danger" class="down-text" @click="getCookie()">
                读Cookie
            </van-button>
            <div>
                {{ cValue }}
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            cValue: '',
            shareTicket:'',
        }
    },
    methods: {
        openUrlScheme(urlScheme) {
            // window.open(urlScheme);
            window.location.href = urlScheme;
        },
        setCookie() {
            let time = Date.now();
            this.$cookie.set("channel", "kingxiaoguang_" + time, 10, '/', ".babyzhiai.net");
            // this.$cookie.set("channel","channel",)
        },
        getCookie() {
            let cValue = this.$cookie.get("channel")
            console.log(cValue)
            this.cValue = cValue;
        },
        getUserAgent() {
            return window.navigator.userAgent.toLowerCase();
        }
    }
}
</script>

<style scoped></style>