<template>
  <div class="more-info" :style="{color:color}">
    <div>
    </div>

    <div>
      查看<a href="" :style="{color:color}">应用权限</a>与<a href="" :style="{color:color}">隐私政策</a>信息
    </div>
    <div class="foot">
      <div>
        Copyright@2010-2023 babyzhiai.net, All Rights Reserved
      </div>
      <div>
        上海挚爱母婴用品有限公司 版权所有
      </div>
      <div>
        沪ICP备10218904号-1
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    color: {type: String, default: "#2c3e50"}
  }
}
</script>
<style>
.more-info {
  margin-top: 20px;
  font-size: 12px;
}

a {
  font-weight: bold;
  text-decoration: underline;
}

a.router-link-exact-active {
  color: #2c3e50;
}
.foot{
  margin-top: 50px;
}
</style>