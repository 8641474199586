<template>
  <div>
    <div>
      <div class="title">
        尊敬的客户，欢迎您注册成为本网站用户。在注册前请您仔细阅读如下服务条款:
      </div>
      <div class="content">
        本协议是您与{{
          appName
        }}（简称"本站"）所有者{{ copName }}（以下简称为"{{ simpleName }}"）之间就{{ appName }}服务等相关事宜所订立的服务协议。请您务必在注册之前认真阅读全部服务协议内容，如有任何疑问，可向本网站咨询。您勾选注册页面"我已阅读并同意"并按照本网站注册程序成功注册为用户，本协议即构成对双方有约束力的法律文件。
      </div>
      <div class="content">
        &nbsp;
      </div>
      <div class="title">
        第1条 本站服务条款的确认和接纳
      </div>
      <div class="content">
        1.1本站的各项电子服务的所有权和运作权归{{ simpleName }}所有。用户同意所有注册协议条款并完成注册程序，才能成为本站的正式用户。用户确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。
      </div>
      <div class="content">
        1.2用户点击同意本协议的，即视为用户确认自己具有享受本站服务、下单购物等相应的权利能力和行为能力，能够独立承担法律责任。
      </div>
      <div class="content">
        1.3如果您在18周岁以下，您只能在父母或监护人的监护参与下才能使用本站。
      </div>
      <div class="content">
        1.4{{ simpleName }}保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。
      </div>
      <div class="content">
        &nbsp;
      </div>
      <div class="title">
        第2条 本站服务
      </div>
      <div class="content">
        2.1{{ simpleName }}通过互联网依法为用户提供互联网信息等服务，用户在完全同意本协议及本站规定的情况下，方有权使用本站的相关服务。
      </div>
      <div class="content">
        2.2用户必须自行准备如下设备和承担如下开支：
      </div>
      <div class="content">
        （1）上网设备，包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；
      </div>
      <div class="content">
        （2）上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。
      </div>
      <div class="content">
        &nbsp;
      </div>
      <div class="title">
        第3条 用户信息
      </div>
      <div class="content">
        3.1用户应自行诚信向本站提供注册资料，用户同意其提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且{{ simpleName }}保留终止用户使用{{ simpleName }}各项服务的权利。
      </div>
      <div class="content">
        3.2用户在本站进行浏览、下单购物等活动时，涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱等隐私信息的，本站将予以严格保密，除非得到用户的授权或法律另有规定，本站不会向外界披露用户隐私信息。
      </div>
      <div class="content">
        3.3用户注册成功后，将产生用户名和密码等账户信息，您可以根据本站规定改变您的密码。用户应谨慎合理的保存、使用其用户名和密码。用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知本站并向公安机关报案。
      </div>
      <div class="content">
        3.4用户在注册时应当选择稳定性及安全性相对较好的电子邮箱，并且同意接受并阅读本网站发往用户的各类电子邮件。如用户未及时从自己的电子邮箱接受电子邮件或因用户电子邮箱或用户电子邮件接收及阅读程序本身的问题使电子邮件无法正常接收或阅读的，只要本网站成功发送了电子邮件，应当视为用户已经接收到相关的电子邮件。电子邮件在发信服务器上所记录的发出时间视为送达时间。
      </div>
      <div class="content">
        3.5用户不得将在本站注册获得的账户借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。
      </div>
      <div class="content">
        3.6用户同意，{{ simpleName }}有权使用用户的注册信息、用户名、密码等信息，登录进入用户的注册账户，进行证据保全，包括但不限于公证、见证等。
      </div>
      <div class="content">
        &nbsp;
      </div>
      <div class="title">
        第4条 用户依法言行义务
      </div>
      <div class="content">
        本协议依据国家相关法律法规规章制定，用户同意严格遵守以下义务：
      </div>
      <div class="content">
        4.1不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；
      </div>
      <div class="content">
        4.2从中国大陆向境外传输资料信息时必须符合中国有关法规；
      </div>
      <div class="content">
        4.3不得利用本站从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；
      </div>
      <div class="content">
        4.4不得干扰本站的正常运转，不得侵入本站及国家计算机信息系统；
      </div>
      <div class="content">
        4.5不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；
      </div>
      <div class="content">
        4.6不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；
      </div>
      <div class="content">
        4.7不得教唆他人从事本条所禁止的行为；
      </div>
      <div class="content">
        4.8不得利用在本站注册的账户进行牟利性经营活动；
      </div>
      <div class="content">
        4.9不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容；
      </div>
      <div class="content">
        用户应不时关注并遵守本站不时公布或修改的各类合法规则规定。
      </div>
      <div class="content">
        本站保有删除站内各类不符合法律政策或不真实的信息内容而无须通知用户的权利。
      </div>
      <div class="content">
        若用户未遵守以上规定的，本站有权作出独立判断并采取暂停或关闭用户帐号等措施。用户须对自己在网上的言论和行为承担法律责任。
      </div>
      <div class="content">
        &nbsp;
      </div>
      <div class="title">
        第5条 商品信息
      </div>
      <div class="content">
        &nbsp; &nbsp; &nbsp;
        本站上的商品价格、数量、是否有货等商品信息随时都有可能发生变动，本站不作特别通知。由于网站上商品信息的数量极其庞大，虽然本站会尽最大努力保证您所浏览商品信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，本站网页显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解；{{ simpleName }}欢迎纠错，并会视情况给予纠错者一定的奖励。
      </div>
      <div class="content">
        &nbsp;
      </div>
      <div class="title">
        第6条 配送
      </div>
      <div class="content">
        6.1销售方将会把商品（货物）送到您所指定的收货地址，所有在本站上列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。
      </div>
      <div class="content">
        6.2因如下情况造成订单延迟或无法配送等，销售方不承担延迟配送的责任：
      </div>
      <div class="content">
        （1）用户提供的信息错误、地址不详细等原因导致的；
      </div>
      <div class="content">
        （2）货物送达后无人签收，导致无法配送或延迟配送的；
      </div>
      <div class="content">
        （3）情势变更因素导致的；
      </div>
      <div class="content">
        （4）不可抗力因素导致的，例如：自然灾害、交通戒严、突发战争等。
      </div>
      <div class="content">
        &nbsp;
      </div>
      <div class="title">
        第7条 知识产权条款
      </div>
      <div class="content">
        本站所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是{{ simpleName }}或其内容提供者的财产，受中国和国际版权法的保护。本站上所有内容的汇编是{{ simpleName }}的排他财产，受中国和国际版权法的保护。本站上所有软件都是{{ simpleName }}或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。
      </div>
      <div class="content">
        &nbsp;
      </div>
      <div class="title">
        第8条 责任限制及不承诺担保
      </div>
      <div class="content">
        8.1除非另有明确的书面说明,本站及其所包含的或以其它方式通过本站提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在"按现状"和"按现有"的基础上提供的。除非另有明确的书面说明,{{ simpleName }}不对本站的运营及其包含在本网站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
      </div>
      <div class="content">
        8.2{{ simpleName }}不担保本站所包含的或以其它方式通过本站提供给您的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从本站发出的电子信件、信息没有病毒或其他有害成分。
      </div>
      <div class="content">
        8.3如因不可抗力或其它本站无法控制的原因使本站销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，{{ simpleName }}会合理地尽力协助处理善后事宜。
      </div>
      <div class="content">
        &nbsp;
      </div>
      <div class="title">
        第9条 协议更新及用户关注义务
      </div>
      <div class="content">
        根据国家法律法规变化及网站运营需要，{{ simpleName }}有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本站上即生效，并代替原来的协议。用户可随时登录查阅最新协议；
        用户有义务不时关注并阅读最新版的协议及网站公告。如用户不同意更新后的协议，可以且应立即停止接受{{ simpleName }}网站依据本协议提供的服务；如用户继续使用本网站提供的服务的，即视为同意更新后的协议。{{ simpleName }}建议您在使用本站之前阅读本协议及本站的公告。
        如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
      </div>
      <div class="content">
        &nbsp;
      </div>
      <div class="title">
        第10条 法律管辖和适用
      </div>
      <div class="content">
        本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。
        如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。
        如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有管辖权的中华人民共和国大陆地区法院提起诉讼。
      </div>
      <div class="content">
        &nbsp;
      </div>
      <div class="title">
        第11条 其他
      </div>
      <div class="content">
        11.1{{ simpleName }}网站所有者是指在政府部门依法许可或备案的{{ simpleName }}网站经营主体。
      </div>
      <div class="content">
        11.2{{ simpleName }}尊重用户和消费者的合法权利，本协议及本网站上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为用户和消费者提供服务。本站欢迎用户和社会各界提出意见和建议，{{ simpleName }}将虚心接受并适时修改本协议及本站上的各类规则。
      </div>
      <div class="content">
        11.3本协议内容中以黑体、加粗、下划线、斜体等方式显著标识的条款，请用户着重阅读。
      </div>
      <div class="content">
        11.4您点击本协议下方的"同意并继续"按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。
      </div>
      <br/>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // appName: {type: String, default: '挚爱商城'}
  },
  data() {
    return {
      appName: "挚爱商城",
      copName: "挚爱商城",
      simpleName: '挚爱',
    }
  },
  created() {
    this.userAgent = navigator.userAgent
    let brand = this.$utils.getUrlKey("brand");
    if (brand && brand.toLowerCase() === 'xiaomi') {
      this.copName = '上海挚爱母婴用品有限公司';
      this.simpleName = '挚爱'
      this.appName = '挚爱商城'
    }
    if (brand && brand.toLowerCase() === 'tongya') {
      this.copName = '北京学利美科技有限公司';
      this.appName = '童芽甄选';
      this.simpleName = '童芽';
    }
  }
}
</script>
<style>
body {
  padding: 10px;
}

.title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}

.content {
  margin-left: -0.2pt;
  font-size: 14px;
}
</style>