<template>
  <div>
    <agreement app-name="快购够"></agreement>
  </div>
</template>
<script>
import Agreement from "@/views/doc/agreement.vue";

export default {
  components: {Agreement},
  created() {
    document.title = '服务协议'
  }
}
</script>