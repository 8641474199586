const appCall = {}
export default appCall

/**
 * 发送js数据的基础操作
 * @param data
 */
appCall.postData = function (data) {
    window['postData'].postMessage(JSON.stringify(data))
}

/**
 * 接收js回调的基础操作
 * @param config
 */
appCall.getJsCallBackData = function (config) {
    window[config.jsApi] = (dataStr) => {
        try {
            if (config.success) {

                let dataJson = JSON.parse(decodeURIComponent(dataStr));
                if (dataJson && dataJson['jsCallBackData']) {
                    config.success(dataJson['jsCallBackData'])
                } else {
                    config.success({})
                }
            }
        } catch (e) {
            if (config.error) {
                config.error(e.toString())
            }
        }
        return true;
    };
}

/**
 * 弹出app警告
 * @param title
 * @param content
 */
appCall.alert = function (title, content) {
    let data = {
        title: title, content: content
    }
    window['appAlert'].postMessage(JSON.stringify(data))
}

/**
 * 商品规格选择
 * @param productId
 */
appCall.sukSelect = function (productId) {
    let data = {
        productId: productId,
    }
    window['skuSelect'].postMessage(JSON.stringify(data))
}

/**
 * 商品规格选择方案2
 * @param config
 */
appCall.sukSelectByConfig = function (config) {
    if (config && config.productId) {
        config.jsApi = 'getSkuSelect'
        appCall.postData(config);
    }
}

/**
 * 判断是否在app中
 * @param config
 */
appCall.isInApp = function (config) {
    if (!config) {
        config = {};
    }
    config.jsApi = 'isInApp'
    appCall.getJsCallBackData(config);
    appCall.postData(config);
}


appCall.getDeviceInfo = function (config) {
    if (!config) {
        config = {};
    }
    config.jsApi = 'getDeviceInfo'
    appCall.getJsCallBackData(config);
    appCall.postData(config);
}


/**
 * 获取应用内用户信息
 * @param config
 */
appCall.getUserData = function (config) {
    if (!config) {
        config = {};
    }
    config.jsApi = 'getUserData'
    appCall.getJsCallBackData(config);
    appCall.postData(config);
}

