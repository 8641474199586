<template>
  <div>
    <div>
      <div class="bigTitle">隐私政策</div>
      <div class="content">
        本政策仅适用于<span class="name">{{ copName }}</span>的<span class="name">{{ appName }}</span>小程序(以下简称本应用)产品或服务。
      </div>
      <PrivacyContent></PrivacyContent>
    </div>
  </div>
</template>
<script>
import PrivacyContent from "@/views/doc/PrivacyContent";

export default {
  components: {PrivacyContent},
  props: {
    appName: {type: String, default: '挚爱母婴商户版'}
  },
  data() {
    return {
      userAgent: '',
      copName: '上海挚爱母婴用品有限公司',
    }
  },
  created() {
    this.userAgent = navigator.userAgent
    let brand = this.$utils.getUrlKey("brand");
    if (brand && brand.toLowerCase() === 'xiaomi') {
      this.copName = '上海挚爱母婴用品有限公司';
    }
  }
}
</script>
<style>
body {
  padding: 10px;
}

.bigTitle {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}

.content {
  margin-left: -0.2pt;
  font-size: 14px;
}

.name {
  font-weight: bold;
}
</style>