/*
 * @Author: xguang 19933226@qq.com
 * @Date: 2024-08-11 15:08:40
 * @LastEditors: xguang 19933226@qq.com
 * @LastEditTime: 2024-08-30 16:36:38
 * @FilePath: /za-h5/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'wapx-lib'
import cookie from 'vue-cookie';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Fingerprint2 from 'fingerprintjs2'
import fingerApi from "./js/finger/fingerApi";
import appCall from "@/js/appCall";
import apiUtils from "@/js/apiUtils";
import corpWxUtils from "@/js/corp/corpWxUtils";
import config from './config'
import utils from "@/js/utils";

Vue.prototype.$utils = utils;
Vue.prototype.$appCall = appCall;
Vue.prototype.$apiUtils = apiUtils;
Vue.prototype.$corpWxUtils = corpWxUtils;
Vue.prototype.$cookie = cookie;
Vue.prototype.$fingerPrintJS = FingerprintJS;
Vue.prototype.$fingerPrintJS2 = Fingerprint2;
Vue.prototype.$fingerApi = fingerApi;
Vue.prototype.$config = config

Vue.config.productionTip = false

Vue.prototype.$getViewPortSize = function () {
    return {
        width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
        height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
    }
}

Vue.use(Vant);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
