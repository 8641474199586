import commons from "@/js/commons";
import {postJsonSimple} from "@/js/http";
import corpCommons from "@/js/corp/corpCommons";

export const zaUserUtils = {}
export default zaUserUtils


zaUserUtils.searchUserData = function (data, success, error) {
    if(data) {
        let url = corpCommons.api_corp_user_search
        postJsonSimple(url, data, success, error)
    }
}