import Vue from "vue";
import VueRouter from "vue-router";
import DownLoad from "../views/download/DownLoad.vue";
import testDownLoad from "../views/download/testDownLoad.vue";
import spuInfo from "../views/spu/index.vue";
import brandHome from "../views/brand/index.vue";
import testHome from "../views/test/index.vue";
import testCorp from "../views/test/test_corp.vue";
import testInApp from "../views/test/test_in_app.vue";
import channelHome from "../views/channel/index.vue";
import agreement from "@/views/doc/agreement";
import privacy_policy from "@/views/doc/privacy_policy";
import privacy_policy_xiaomi from "@/views/doc/privacy_policy_xiaomi";
import privacy_policy_mini from "@/views/doc/privacy_policy_mini";
import privacy_policy_mini_buy from "@/views/doc/privacy_policy_mini_buy.vue";
import agreement_mini_buy from "@/views/doc/agreement_mini_buy.vue";
import wxView from "@/views/Wx.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: DownLoad,
    },
    {
        path: "/down",
        name: "下载",
        component: DownLoad,
    },
    {
        path: "/demo",
        name: "挚爱测试下载",
        component: testDownLoad,
    },
    {
        path: "/spu",
        name: "商品详情",
        component: spuInfo,
    },
    {
        path: "/brand",
        name: "厂商页面",
        component: brandHome,
    },
    {
        path: "/wx",
        name: "wxView",
        component: wxView,
    },
    {
        path: "/corp_user_data",
        name: "用户信息",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/corp/corp_user_data.vue"),
    },
    {
        path: "/corp_spu_search",
        name: "商品搜索",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/corp/pages/corp_spu_search.vue"),
    },
    {
        path: "/corp_user_order",
        name: "客户订单",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/corp/pages/corp_user_order.vue"),
    },
    {
        path: "/test",
        name: "测试页面",
        component: testHome,
    },
    {
        path: "/test_corp",
        name: "企业微信测试页面",
        component: testCorp,
    },
    {
        path: "/test_in_app",
        name: "测试页面_InApp",
        component: testInApp,
    },
    {
        path: "/channel",
        name: "下载渠道测试页面",
        component: channelHome,
    },
    {
        path: "/agreement",
        name: "挚爱母婴服务协议",
        component: agreement,
    },
    {
        path: "/privacy_policy",
        name: "挚爱母婴隐私政策",
        component: privacy_policy,
    },
    {
        path: "/privacy_policy_mi",
        name: "挚爱母婴隐私政策",
        component: privacy_policy_xiaomi,
    },
    {
        path: "/privacy_policy_mini",
        name: "挚爱母婴隐私政策",
        component: privacy_policy_mini,
    },
    {
        path: "/agreement_mini_buy",
        name: "快购够服务协议",
        component: agreement_mini_buy,
    },
    {
        path: "/privacy_policy_mini_buy",
        name: "快购够隐私政策",
        component: privacy_policy_mini_buy,
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },
];

const router = new VueRouter({
    routes,
    mode: "history",
});

export default router;
