/*
 * @Author: xguang 19933226@qq.com
 * @Date: 2024-08-30 16:35:56
 * @LastEditors: xguang 19933226@qq.com
 * @LastEditTime: 2024-09-02 09:13:56
 * @FilePath: /za-h5/src/js/wwUtils.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import * as ww from '@wecom/jssdk'
import corpCommons from "@/js/corp/corpCommons";
import apiUtils from "@/js/apiUtils";
import zaUserUtils from "@/js/corp/zaUserUtils";
import {postJsonSimple} from "@/js/http";

export const corpWxUtils = {}
export default corpWxUtils

export let url = "https://h5.babyzhiai.net/corp_user_data"


//npm install @wecom/jssdk
// transpileDependencies: ['@wecom/jssdk'] 加入到vue.config.js
//https://developer.work.weixin.qq.com/document/path/98705


corpWxUtils.getVersion = function () {
    return ww.SDK_VERSION
}

corpWxUtils.register = async function (currentUrl, configResult, configAgentResult) {

    console.log(`==register== ${corpCommons.corpAppId}  ${corpCommons.corpAgentId}  ${currentUrl}`)
    // 预先获取签名
    const configSignature = await corpWxUtils.getConfigSignature(currentUrl);
    const agentConfigSignature = await corpWxUtils.getAgentConfigSignature(currentUrl);


    ww.register({
        corpId: corpCommons.corpAppId,       // 必填，当前用户企业所属企业ID
        agentId: corpCommons.corpAgentId,                  // 必填，当前应用的AgentID
        jsApiList: ['getExternalContact', 'shareToExternalChat', 'sendChatMessage', 'getCurExternalContact', 'shareToExternalContact', 'sendChatMessage', 'getCurExternalChat', 'getContext', 'createExternalPayment', 'getCurExternalChat'], // 必填，需要使用的JSAPI列表
        getConfigSignature: () => configSignature,
        getAgentConfigSignature: () => agentConfigSignature,
        onConfigSuccess: (res) => {
            console.log(res)
        },
        onConfigFail: (res) => {
            console.log(res)
        },
        onConfigComplete: (res) => {
            console.log(res)
            alert(JSON.stringify(res));
            if (configResult) {
                configResult(res)
            }
        },
        onAgentConfigSuccess: (res) => {
            console.log(res)
        },
        onAgentConfigFail: (res) => {
            console.log(res)
        },
        onAgentConfigComplete: (res) => {
            console.log(res)
            if (configAgentResult) {
                configAgentResult(res)
            }
        },
    })
}

// 修改 wxUtils.getConfigSignature 函数
corpWxUtils.getConfigSignature = function (currentUrl) {
    console.log("==getConfigSignature==")
    return new Promise((resolve, reject) => {
        apiUtils.getCorpJsApiSign(currentUrl, (res) => {
            if (res.code === 200) {
                let data = {timestamp: res.data.timestamp, nonceStr: res.data.nonceStr, signature: res.data.signStr}
                resolve(data);
            } else {
                reject(new Error('Failed to get config signature'));
            }
        });
    });
}

// 修改 wxUtils.getAgentConfigSignature 函数
corpWxUtils.getAgentConfigSignature = function (currentUrl) {
    console.log("==getAgentConfigSignature==")
    return new Promise((resolve, reject) => {
        apiUtils.getCorpAgentJsApiSign(currentUrl, (res) => {
            if (res.code === 200) {
                let data = {timestamp: res.data.timestamp, nonceStr: res.data.nonceStr, signature: res.data.signStr}
                resolve(data);
            } else {
                reject(new Error('Failed to get agent config signature'));
            }
        });
    });
}


/**
 * 判断当前进入状态
 * contact_profile 从联系人详情进入 3.0.24
 * single_chat_tools 从单聊会话的工具栏进入 3.0.24
 * group_chat_tools 从群聊会话的工具栏进入 3.0.24
 * chat_attachment 从会话的聊天附件栏进入 3.1.6
 * single_kf_tools 从微信客服的工具栏进入 3.1.10
 * normal 除以上场景之外进入，例如工作台，聊天会话等 3.0.24
 * @param callBack
 */
corpWxUtils.getContext = function (callBack) {
    ww.getContext({
        success: (res) => {
            console.log(res)
            if (callBack) {
                callBack(res)
            }
        }, fail: (res) => {
            console.log(res)
            if (callBack) {
                callBack(res)
            }
        }
    }).then(r => {
    })
}

/**
 * 获取当前联系人userId
 * @param callBack
 */
corpWxUtils.getCurExternalContact = function (callBack) {
    ww.getCurExternalContact({
        success: (res) => {
            console.log(res)
            if (callBack) {
                callBack(res)
            }
        }, fail: (res) => {
            console.log(res)
            if (callBack) {
                callBack(res)
            }
        }
    }).then(r => {
    })
}

/**
 * 获取当前群聊ID
 * @param callBack
 */
corpWxUtils.getCurExternalChat = function (callBack) {
    ww.getCurExternalChat({
        complete: (res) => {
            console.log(res)
            if (callBack) {
                callBack(res)
            }
        }
    }).then(r => {
    })
}


corpWxUtils.searchUser = function (extUserId, success, error) {
    let data = {
        filterArray: [
            {
                rangeText: `corpExtUserId=${extUserId}`
            }
        ]
    }
    zaUserUtils.searchUserData(data, success, error)
}


corpWxUtils.getChatGroupDetail = function (chatId, success, error) {
    let url = corpCommons.api_get_corp_chat_group_detail
    let data = {
        chat_id: chatId,
    }
    postJsonSimple(url, data, success, error)
}


corpWxUtils.getMiniUrlLink = function (data, success, error) {
    if (data) {
        let url = corpCommons.api_spu_url_link
        postJsonSimple(url, data, success, error)
    }
}

// 修改 wxUtils.getAgentConfigSignature 函数
corpWxUtils.getMiniUrlLinkAsync = function (data) {
    console.log("==getMiniUrlLinkAsync==")
    return new Promise((resolve, reject) => {
        corpWxUtils.getMiniUrlLink(data, (res) => {
            if (res.code === 200) {
                let data = res.data
                resolve(data);
            } else {
                reject(new Error('Failed to get agent config signature'));
            }
        });
    });
}


corpWxUtils.getPay = function (totalFee, desc) {
    ww.createExternalPayment({
        paymentType: 0,
        totalFee: totalFee,
        description: desc,
        success: (res) => {
            alert(JSON.stringify(res))
        },
        fail: (res) => {
            alert(JSON.stringify(res))
        },
        cancel: (res) => {
            alert(JSON.stringify(res))
        },
        complete: (res) => {
            alert(JSON.stringify(res))
        }
    }).then(r => {

    })
}


corpWxUtils.searchSpu = function (data, success, error) {
    if (data) {
        data.enterChat = true;
        let url = corpCommons.api_spu_search
        postJsonSimple(url, data, success, error)
    }
}

corpWxUtils.sendCorpChatMessage = function (corpMessageData, complete) {
    if (corpMessageData) {
        corpMessageData.enterChat = true;
        corpMessageData.complete = (res) => {
            console.log(res)
            if (complete) {
                complete(res)
            }
        }
        console.log(corpMessageData)
        ww.sendChatMessage(corpMessageData,).then(r => {
        })
    }
}