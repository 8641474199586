/*
 * @Author: xguang 19933226@qq.com
 * @Date: 2024-08-11 15:08:40
 * @LastEditors: xguang 19933226@qq.com
 * @LastEditTime: 2024-08-30 16:45:09
 * @FilePath: /za-h5/src/js/apiUtils.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import commons from "@/js/commons";
import { getJsonSimple,postJsonSimple } from "@/js/http";

const apiUtils = {}
export default apiUtils

/**
 * 获取app最新版本信息
 * @param success
 * @param error
 */
apiUtils.getAppVersion = function (success, error) {
    let url = commons.api_get_version
    url = url + "?t=" + Date.now()
    getJsonSimple(url, success, error)
}


apiUtils.getCorpJsApiTicket = function (success, error) {
    let url = commons.api_get_corp_js_api_ticket
    getJsonSimple(url, success, error)
}


apiUtils.getCorpAgentJsApiTicket = function (success, error) {
    let url = commons.api_get_corp_agent_js_api_ticket
    getJsonSimple(url, success, error)
}


apiUtils.getCorpJsApiSign = function (agentUrl, success, error) {
    let url = commons.api_get_corp_js_api_ticket_sign
    let data={ url: agentUrl }
    postJsonSimple(url, data, success, error)
}

apiUtils.getCorpAgentJsApiSign = function (agentUrl, success, error) {
    let url = commons.api_get_corp_agent_js_api_ticket_sign
    let data={ url: agentUrl }
    postJsonSimple(url, data, success, error)
}

