<template>
  <div>
    <div>
      商品页面
    </div>
    <div>
      当前id:{{ id }}
    </div>
  </div>

</template>
<script>
export default {
  data() {
    return {
      id: '',
    }
  },
  created() {
    this.id = this.$utils.getUrlKey("id");
  },
  methods: {
    init() {

    }
  }
}
</script>