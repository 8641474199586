<template>
  <div>
    <button @click="openMini">打开挚爱母婴小程序</button>
  </div>
</template>
<script>
export default {
  onLoad(){
    this.openMini();
  },
  onShow() {
    this.openMini();
  },
  methods:{
    openMini(){
      window.location.href = 'weixin://dl/business/?t=vbCAuVU2zCo';
    }
  }
}
</script>

