<template>
  <div>
    <div>IP信息: {{ remoteIp }}</div>
    <!--    <div>{{ infoData }}</div>-->
    <div>UserAgent:{{ userAgent }}</div>
    <div>======UA信息=====================</div>
    <div>UserAgent2:={{ userAgent2 }}=</div>
    <div>length:{{ userAgent2.length }}</div>
    <div>userAgentSize:{{ userAgentSize }}</div>
    <div>参数:{{ paramMap }}</div>
    <div>======指纹信息=====================</div>
    <div v-if="visitorId">指纹1:{{ visitorId }}</div>
    <div v-if="visitorId2">指纹2:{{ visitorId2 }}</div>
    <div>======findFinger=====================</div>
    <div v-if="findFinger">{{ findFinger }}</div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      channel: '',
      remoteIp: '',
      addressList: '',
      infoData: {},
      userAgent: '',
      userAgent2: '',
      userAgentSize: 0,
      visitorId: '',
      visitorId2: '',
      info: {},
      paramMap: {},
      findFinger: {},
    }
  },
  beforeMount() {
  },
  created() {
    this.getParams();
    this.getDeviceInfo();
    this.handelFingerprints();
  },
  methods: {
    getParams() {
      console.log(this.$route.query)
      this.paramMap = this.$route.query
    },
    getDeviceInfo() {
      this.$fingerApi.getDeviceInfo((res) => {
        console.log(res)
        if (res.code === 200) {
          this.remoteIp = res.data.ip;
        }
      })
    },
    //处理指纹
    handelFingerprints() {
      this.initFinger2((finger) => {
        if (finger) {
          this.visitorId2 = finger;
          var _noParams = (JSON.stringify(this.paramMap) === "{}");
          if (_noParams) {
            this.$fingerApi.findFingerParams(finger, (res) => {
              console.log(res)
              if (res.code === 200) {
                this.findFinger = res.data;
              }
            })
          } else {
            this.$fingerApi.saveFinger(finger, this.paramMap, (res) => {
              console.log(res)
            })
          }
        }
      });
      this.initFinger();
    },
    initFinger2(finger) {
      var that = this;
      this.userAgent = navigator.userAgent;
      this.$fingerPrintJS2.get(function (components) {
        const values = components.map(function (component, index) {
          // console.log(components) // an array of components: {key: ..., value: ...}
          // var values = components.map(function (component) {
          //   return component.value
          // })
          // console.log(values);
          // var hash = that.$fingerPrintJS2.x64hash128(values.join(''), 31)
          console.log(component.value)
          if (index === 0) {
            var userAgent = component.value;
            //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
            var newUserAgent = userAgent
                .replace(/NetType\/[\w.()]*/, '')
                .replace(/Safari\/[\w.()]*/, '')
                .replace(/Version\/[\w.()]*/, '')
                .replace(/Edg\/[\w.()]*/, '')
                .replace(/Chrome\/[\w.()]*/, '')
                .replace(/MicroMessenger\/[\w.()]*/, '')
                .replace(/Language\/[\w.()]*/, '')
                .replace(' ', '')
                .replace('  ', '')
                .replace('\n', '')
            console.log(newUserAgent);
            that.userAgent2 = newUserAgent;
            that.userAgentSize = newUserAgent.length;
            return component.value = newUserAgent.length;
          }
          return component.value
        })
        // 生成最终id murmur
        const murmur = that.$fingerPrintJS2.x64hash128(values.join(''), 31)
        // console.log("murmur:" + murmur);

        if (finger) {
          finger(murmur)
        }
      })
    },
    initFinger() {
      this.$fingerPrintJS.load().then(fp => {
        // The FingerprintJS agent is ready.
        // Get a visitor identifier when you'd like to.
        fp.get().then(result => {
          // This is the visitor identifier:
          const visitorId = result.visitorId;
          console.log(result);
          console.log(visitorId);
          this.visitorId = visitorId;
        });
      });
    },
    getHardwareInfo() {
      var canvas = document.createElement("canvas");
      if (canvas && "function" == typeof canvas.getContext) {
        var _context = canvas.getContext('webgl');
        var debugInfo = _context.getExtension('WEBGL_debug_renderer_info');
        if (!debugInfo) {
          return null;
        }
        var vendor = _context.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
        var renderer = _context.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
        this.info = {
          vendor: vendor,
          renderer: renderer
        };
      }
    },
  }
}
</script>