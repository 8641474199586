import commons from "@/js/commons";
import {postJsonSimple} from "@/js/http";

const fingerApi = {}
export default fingerApi


fingerApi.getDeviceInfo = function (success, error) {
    let data = {}
    let url = commons.api_finger_device_info
    postJsonSimple(url, data, success, error)
}

/**
 * 保存设备指纹和参数
 * @param finger
 * @param paramList
 * @param success
 * @param error
 */
fingerApi.saveFinger = function (finger, paramMap, success, error) {
    if (finger) {
        let data = {
            finger: finger,
            paramMap: paramMap
        }
        let url = commons.api_finger_save
        postJsonSimple(url, data, success, error)
    }
}

/**
 * 查找已保存的指纹信息和参数
 * @param finger
 * @param success
 * @param error
 */
fingerApi.findFingerParams = function (finger, success, error) {
    if (finger) {
        let data = {
            finger: finger,
        }
        let url = commons.api_finger_find
        postJsonSimple(url, data, success, error)
    }
}