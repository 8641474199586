/*
 * @Author: xguang 19933226@qq.com
 * @Date: 2024-08-11 15:08:40
 * @LastEditors: xguang 19933226@qq.com
 * @LastEditTime: 2024-09-02 09:02:28
 * @FilePath: /za-h5/src/js/commons.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const commons = {};
export default commons;


let corpAppId = 'ww2f3a48301814994b';
let agentAppId = '1000002';
// let agentAppId = corpAppId + '_1000002';

commons.api_host = "https://api.kumob.cn/za"
commons.api_host_test = "https://api.kumob.cn/za_test"
commons.wechat_api_host = "https://api.kumob.cn/wx"

commons.api_finger_device_info = commons.api_host_test + "/api/finger/get_device_info"

commons.api_finger_save = commons.api_host_test + "/api/finger/za9527/save"
commons.api_finger_find = commons.api_host_test + "/api/finger/za9527/find"

commons.api_get_version = commons.api_host + "/config/version.json"


commons.api_get_corp_js_api_ticket = commons.wechat_api_host + `/api/corp/get_jsapi_ticket/${corpAppId}`
commons.api_get_corp_agent_js_api_ticket = commons.wechat_api_host + `/api/corp/get_agent_jsapi_ticket/${corpAppId}_${agentAppId}`

commons.api_get_corp_js_api_ticket_sign = commons.wechat_api_host + `/api/corp/get_jsapi_ticket/sign/${corpAppId}`
commons.api_get_corp_agent_js_api_ticket_sign = commons.wechat_api_host + `/api/corp/get_agent_jsapi_ticket/sign/${corpAppId}_${agentAppId}`